import React from "react";

import Layout from "../components/layout";
import about from '../images/about.png';

function AboutPage() {
  return (
    <Layout name="about">
      <div className="text-gray-500 flex flex-col md:flex-row">
        <div className="w-full md:w-2/5 h-1/2 md:min-h-full p-2">
          <div className="flex content-center items-center overflow-hidden">
            <img className="flex-shrink-0 min-w-full min-h-full" src={about} />
          </div>
        </div>
        <div className="w-full md:w-3/5 p-2">
          <section className="pr-4">
            <h2 className="text-4xl mb-8 leading-loose">
              Quem Somos
            </h2>
          </section>
          <div className="leading-loose ml-2">
            <p>O jardim/creche São Vicente, como instituição de ensino particular, enquadra-se nos objectivos do sistema educativo nacional.</p>
            <p>Possui um projecto educativo e um regulamento interno baseado nas orientações curriculares.</p>
            <p>A instituição pretende ser um espaço educativo democrático, onde as oportunidades de aprendizagem são iguais para todos independentemente da cor, da crença e das caracteristícas indivíduais de cada um. É imperativo que todos nesta instituição tenham o máximo de respeito por cada criança.</p>
            <p>Organiza o seu processo de ensino-aprendizagem em torno de quatro pilares do conhecimento, que durante toda a vida serão os instrumentos que a criança terá para a sua adequação ao mundo em constante mudança. Esses pilares do conhecimento são:</p>
            <ul className="list-disc list-inside ml-2">
              <li>Aprender a ser</li>
              <li>Aprender a fazer</li>
              <li>Aprender a conhecer</li>
              <li>Aprender a viver juntos</li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AboutPage;
